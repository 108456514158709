import {HttpClient, HttpEvent} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {API} from '../apiutil';
import {APP_CONFIG as environment} from '../environments/environment';
import {IApiResponse, ISearchCtx, ITool} from '../interfaces';

@Injectable()
export class ImporterService {
  constructor (private readonly http: HttpClient) {
  }

  public upload (formData: FormData): Observable<HttpEvent<any>> {
    return this.http.post<IApiResponse<any>>(`${environment.base}/importer`, formData, {
      observe: 'events',
      reportProgress: true,
    });
  }

  public list (ctx: ISearchCtx): Observable<IApiResponse<ITool[]>> {
    return this.http.get<IApiResponse<ITool[]>>(`${environment.base}/importer`, {params: API.makeParams(ctx)});
  }
}
