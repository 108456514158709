import {HttpClient, HttpEvent} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {API} from '../apiutil';
import {IPackage, ITool} from '../app/tools/interfaces';
import {APP_CONFIG as environment} from '../environments/environment';
import {IApiResponse, ISearchCtx} from '../interfaces';

@Injectable()
export class ToolService {
  constructor (private readonly http: HttpClient) {
  }

  public upsert (tool: ITool): Observable<IApiResponse<any>> {
    return of();
  }

  public list (ctx: ISearchCtx): Observable<IApiResponse<ITool[]>> {
    return this.http.get<IApiResponse<ITool[]>>(`${environment.base}/tools`, {params: API.makeParams(ctx)});
  }

  public readToolByCatNr (catNr: string) {
    return this.http.get<IApiResponse<any>>(`${environment.base}/tools/${catNr}`);
  }


  public readForScanByCatNr (catNr: string) {
    return this.http.get<IApiResponse<any>>(`${environment.base}/tools/scan/${catNr}`);
  }

  public saveTool (tool: ITool) {
    return this.http.post<IApiResponse<any>>(`${environment.base}/tools`, tool);
  }

  public savePackage (pkg: IPackage) {
    return this.http.post<IApiResponse<any>>(`${environment.base}/tools/package`, pkg);
  }

  public listPackages (ctx: ISearchCtx) {
    return this.http.get<IApiResponse<IPackage[]>>(`${environment.base}/tools/package`, {params: API.makeParams(ctx)});
  }

  public readPackageByEAN (EAN: string) {
    return this.http.get<IApiResponse<IPackage>>(`${environment.base}/tools/package/${EAN}`);
  }

  public readPackageByCatNr(catNr: any) {
    return this.http.get<IApiResponse<IPackage>>(`${environment.base}/tools/package/catNr/${catNr}`);
  }

  public updatePackage (pkg: IPackage): Observable<IApiResponse<IPackage>> {
    return this.http.put<IApiResponse<IPackage>>(`${environment.base}/tools/package/${pkg.catNr}`, pkg);
  }
}
