import { Injectable } from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {MessageService} from 'primeng/api';
import {EUserRole, IUser} from '../interfaces';
import {ErrorAction, SuccessAction} from './actions';

export interface IGlobalState {
  me?: IUser;
}

@Injectable()
@State<IGlobalState>({
  name: 'global',
  defaults: {
    me: undefined,
  },
})
export class GlobalState {

  constructor (private readonly messageService: MessageService) {
  }

  @Selector()
  public static me(state: IGlobalState): IUser {
    return state.me || { roles: [EUserRole.ADMIN], id: '' } as IUser;
  }



  @Action(ErrorAction, {cancelUncompleted: false})
  public openErrorNotice(context: StateContext<IGlobalState>, action: ErrorAction) {
    this.messageService.add({
      summary: 'Napaka',
      severity: 'error',
      detail: action.payload.message,
      data: action.payload.action,
    });
  }

  @Action(SuccessAction, {cancelUncompleted: false})
  public openSuccessNotice(context: StateContext<IGlobalState>, action: SuccessAction) {
    this.messageService.add({
      summary: 'Uspešno',
      severity: 'success',
      detail: action.payload.message,
      data: action.payload.action,
    });
  }

}
