import {DOCUMENT} from '@angular/common';
import {Inject, Injectable, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngxs/store';
import {SetUserAction} from '../state/actions';

@Injectable({providedIn: 'root'})
export class AuthService {
  private key?: string;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly router: Router,
    private readonly store: Store,
    private readonly zone: NgZone,
  ) {
    if (!this.key && localStorage) {
      this.key = localStorage.getItem('grlica.token') || undefined;
    }

    console.log(Math.random());
  }

  public getDomain(): string {
    return this.document.location.hostname;
  }

  public setKey(key: string): void {

    this.key = key;

    if (localStorage) {
      localStorage.setItem('grlica.token', key);
    }
  }

  public apiKey(): string | undefined {
    return this.key;
  }

  public logout(returnTo?: string) {
    this.key = undefined;
    this.store.dispatch(SetUserAction.dispatch(undefined));

    if (localStorage) {
      localStorage.removeItem('grlica.token');
    }

    this.zone.run(() => this.router.navigate(['/login'], {queryParams: {returnTo}}));
  }
}
