import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {AuthService} from './auth.service';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable({providedIn: 'root'})
export class AuthInterceptorService implements HttpInterceptor {
  public static readonly ERROR_STATUS_REDIRECT_CODES: number[] = [401, 403, 413];
  constructor(private readonly authService: AuthService, private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let clonedRequest: HttpRequest<any>;
    if (!!this.authService.apiKey()) {
      clonedRequest = req.clone({
        headers: req.headers.set('Authorization', this.authService.apiKey() || 'MISSING')
      });
    } else {
      console.log('ni api keya...');
      clonedRequest = req.clone();
    }

    return next.handle(clonedRequest)
      .pipe(
        catchError((err: HttpErrorResponse): Observable<any> => {
          if (AuthInterceptorService.ERROR_STATUS_REDIRECT_CODES.includes(err.status)) {
            this.authService.logout(this.router.routerState.snapshot.url);
          }

          return throwError(err);
        })
      );
  }
}
