import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {API} from '../apiutil';
import {APP_CONFIG as environment} from '../environments/environment';
import {IApiResponse, ISearchCtx, ISettings} from '../interfaces';

@Injectable()
export class SettingsService {
  constructor(private readonly http: HttpClient) {
  }

  public readSettings(ctx: ISearchCtx):  Observable<IApiResponse<ISettings>> {
    return this.http.get<IApiResponse<ISettings>>(`${environment.base}/settings`, {params: API.makeParams(ctx)});
  }

  public upsert(settings: ISettings):  Observable<IApiResponse<ISettings>> {
    return this.http.post<IApiResponse<ISettings>>(`${environment.base}/settings/${settings.id}`, settings);
  }
}
