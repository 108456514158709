import {IError, IUser} from '../interfaces';

export class SuccessAction {
  public static type: string = '[global] success action';

  constructor(public readonly payload: any) {
  }

  public static dispatch(payload: IError): SuccessAction {
    return new SuccessAction(payload);
  }
}


export class ErrorAction {
  public static type: string = '[global] error action';

  constructor(public readonly payload: IError) {
  }

  public static dispatch(payload: IError): ErrorAction {
    return new ErrorAction(payload);
  }
}

export class SetUserAction {
  public static type: string = '[global] set user action';

  constructor(public readonly user?: IUser) {
  }

  public static dispatch(user?: IUser): SetUserAction {
    return new SetUserAction(user);
  }
}
