import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import {MessageService, SharedModule} from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TimelineModule } from 'primeng/timeline';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { MultiSelectModule } from 'primeng/multiselect';
import { ToastModule } from 'primeng/toast';

@NgModule({
  declarations: [],
  imports: [
    ButtonModule,
    CardModule,
    SharedModule,
    TabViewModule,
    MenubarModule,
    InputTextModule,
    InputNumberModule,
    InputTextareaModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    RatingModule,
    MessageModule,
    DragDropModule,
    ContextMenuModule,
    CommonModule,
    OrganizationChartModule,
    TreeModule,
    DialogModule,
    CheckboxModule,
    RadioButtonModule,
    InputSwitchModule,
    InputMaskModule,
    AutoCompleteModule,
    DropdownModule,
    CalendarModule,
    TimelineModule,
    EditorModule,
    TooltipModule,
    MultiSelectModule,
    BlockUIModule,
    ProgressSpinnerModule
  ],
  exports: [
    ButtonModule,
    SharedModule,
    CardModule,
    TabViewModule,
    MenubarModule,
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    RatingModule,
    MessageModule,
    DragDropModule,
    ContextMenuModule,
    OrganizationChartModule,
    TreeModule,
    DialogModule,
    InputNumberModule,
    InputTextareaModule,
    CheckboxModule,
    RadioButtonModule,
    InputSwitchModule,
    InputMaskModule,
    AutoCompleteModule,
    DropdownModule,
    CalendarModule,
    TimelineModule,
    EditorModule,
    TooltipModule,
    MultiSelectModule,
    BlockUIModule,
    ProgressSpinnerModule,
    ToastModule,
    FileUploadModule,
  ],
  providers: [
    MessageService,
  ],
})
export class PagesModule {
}
