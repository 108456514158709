import {Component, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {MenuItem, PrimeIcons} from 'primeng/api';
import {Observable} from 'rxjs';
import {AuthService} from '../../api/auth.service';
import {APP_CONFIG} from '../../environments/environment';
import {IUser} from '../../interfaces';
import {GlobalState} from '../../state';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent implements OnInit {
  // @ts-ignore
  items: MenuItem[];

  @Select(GlobalState.me) me$: Observable<IUser>;

  constructor (private readonly store: Store, private readonly authService: AuthService) {
  }

  ngOnInit () {
    this.items = [
      {
        label: 'Grlica',
        items: [
          {
            label: 'Orodja',
            icon: 'pi pi-fw ' + PrimeIcons.COG,
            items: [
              {
                label: 'Orodja',
                icon: 'pi pi-fw ' + PrimeIcons.COG,
                routerLink: '/tools/tools',
              },
              {
                label: 'Kompleti',
                icon: 'pi pi-fw ' + PrimeIcons.BRIEFCASE,
                routerLink: '/tools/packages',
                items: [{
                  label: 'Seznam',
                  icon: 'pi pi-fw ' + PrimeIcons.BRIEFCASE,
                  routerLink: '/tools/packages',
                },
                  {
                    label: 'Dodaj',
                    icon: 'pi pi-fw ' + PrimeIcons.PLUS_CIRCLE,
                    routerLink: '/tools/packages/add',
                  },
                ],
              },

              {
                label: 'Uvoz',
                icon: 'pi pi-fw ' + PrimeIcons.UPLOAD,
                routerLink: '/importer/add',
              },
              {
                label: 'Seznam uvozov',
                icon: 'pi pi-fw ' + PrimeIcons.LIST,
                routerLink: '/importer',
              },
            ],
          },
          {label: `Verzija ${APP_CONFIG.version}`}
        ],
      },
      {
        label: 'Skeniranje',
        icon: 'pi pi-fw ' + PrimeIcons.CAMERA,
        routerLink: '/scanning'
      },
      {
        label: 'Arhiv',
        icon: 'pi pi-fw ' + PrimeIcons.BOX,
        routerLink: '/readings',
      },
      {
        label: 'Nastavitve',
        icon: 'pi pi-fw ' + PrimeIcons.COG,
        routerLink: '/settings',
      },
      {
        label: 'Uporabniki',
        icon: 'pi pi-fw pi-users',
        routerLink: '/user',
      },
      /* {
        label: 'Razvoj',
        icon: 'pi pi-fw pi-info-circle',
        routerLink: '/info',
      } */
    ];
  }


  logout () {
    this.authService.logout();
  }
}
