import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxsModule} from '@ngxs/store';
import {ApiModule} from '../api/api.module';
import {CoreModule} from '../core/core.module';
import {PagesModule} from '../pages/pages.module';
import {GlobalState} from '../state';
import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';
import {BaseComponent} from './base/base.component';
import {AuthService} from "../api/auth.service";

@NgModule({
  declarations: [AppComponent, BaseComponent],
  imports: [
    ApiModule,
    CoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PagesModule,
    NgxsModule.forRoot([GlobalState])
  ],
  providers: [AuthService],
  bootstrap: [AppComponent]

})
export class AppModule {
}
