import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {API} from '../apiutil';
import {IFullScan} from '../app/tools/interfaces';
import {APP_CONFIG as environment} from '../environments/environment';
import {IApiResponse, ISearchCtx} from '../interfaces';

@Injectable()
export class ScanService {
  constructor (private readonly http: HttpClient) {
  }

  public saveScan (scan: IFullScan) {
    return this.http.post<IApiResponse<any>>(`${environment.base}/readings`, scan);
  }

  public list (ctx: ISearchCtx) {
    return this.http.get<IApiResponse<IFullScan[]>>(`${environment.base}/readings`, {params: API.makeParams(ctx)})
  }

  public readById (id: string) {
    return this.http.get<IApiResponse<IFullScan>>(`${environment.base}/readings/${id}`)
      .pipe(
        map((resp: IApiResponse<IFullScan>) => resp.data),
      );
  }
}
