import {EItemType} from '../app/tools/interfaces';

export interface IExtendedRequest {
  user?: IUser;
  [key: string]: any;
}

export interface IApiResponse<T> {
  data: T;
  count?: number;
  ctx?: ISearchCtx;
  time?: Date;
}


export interface INotification {
  message: string;
  action?: string;
}

export interface IError extends INotification {}


export interface ISearchCtx {
  size?: number;
  page?: number;
  id?: string;
  q?: string;
  key?: string | undefined;
  mode?: 'ASC' | 'DESC' | undefined;
  from?: string | Date;
  to?: string | Date;
  media?: any;
  customer?: string;
  tags?: string[] | string;
  lang?: string;
  exposed?: boolean;
  p?: string[];
  f?: string;
  category?: string;
  full?: string;
  type?: EItemType;
}

export interface ICountable<T> {
  rows: T[];
  count: number;
  errors?: any[];
}

export interface IUser {
  id?: string;
  title?: string;
  eMail: string;
  roles: EUserRole[],
  apiKey: string;
  enabled: boolean;
  lastAction?: Date | string;
  image: string | null;
}


export enum EUserRole {
  SYSOP = "sysop",
  ADMIN = "admin",
  READONLY = "readonly",
  EDITOR = "editor",
  API = "api",
  CUSTOMER = "customer",
}

export interface ISettings {
  id: string;
  value: string;
}

export enum EQRType {
  ITEM = "item",
  PACKAGE = "package",
}

export interface IQr {
  type: EQRType;
  EAN?: string;
  code?: string;
  model: string;
  title?: string;
  serial?: string;
  extra?: string;
  raw?: string;
}

export interface ITool {
  catNr: string;
  title: string;
  EAN: string;
}
