import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {IApiResponse, IUser} from '../interfaces';
import  { APP_CONFIG as environment } from '../environments/environment';

@Injectable()
export class UserService {
  constructor (private readonly http: HttpClient) {
  }

  public login (eMail: string, password: string): Observable<IUser | undefined> {
    return this.http.post(`${environment.base}/user/login`, { eMail, password })
      .pipe(
        map((response: any) => response.data)
      );
  }

  public check (): Observable<IApiResponse<IUser | undefined>> {
    return this.http.get<IApiResponse<IUser>>(`${environment.base}/user`)
      .pipe(
        catchError(() => {
          return of({ data: undefined });
        })
      );
  }

  public patchUser (id: string, values: Partial<IUser>): Observable<IApiResponse<IUser>> {
    return this.http.patch<IApiResponse<IUser>>(`${environment.base}/user/${id}`, values);
  }

  public addUser (user: Partial<IUser>): Observable<IApiResponse<IUser>> {
    return this.http.post<IApiResponse<IUser>>(`${environment.base}/user`, user);
  }

  public list (): Observable<IApiResponse<IUser[]>> {
    return this.http.get<IApiResponse<IUser[]>>(`${environment.base}/user/list`);
  }
}
