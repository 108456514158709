import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {BaseComponent} from './base/base.component';

const routes: Routes = [
  {
    path: 'login',
    pathMatch: 'prefix',
    loadChildren: () => import('../login/login.module').then(m => m.LoginModule)
  },
  {
    path: '',
    pathMatch: 'prefix',
    component: BaseComponent,
    children: [
      {
        path: 'user',
        loadChildren: () => import('../user/user.module').then(m => m.UserModule),
      },
      {
        path: 'importer',
        pathMatch: 'prefix',
        loadChildren: () => import('../app/importer/importer.module').then(m => m.ImporterModule),
      },
      {
        path: 'scanning',
        pathMatch: 'prefix',
        loadChildren: () => import('../app/scanning/scanning.module').then(m => m.ScanningModule),
      },
      {
        path: 'readings',
        pathMatch: 'prefix',
        loadChildren: () => import('../app/reading/reading.module').then(m => m.ReadingModule),
      },
      {
        path: 'printing',
        pathMatch: 'prefix',
        loadChildren: () => import('../app/printing/printing.module').then(m => m.PrintingModule),
      },
      {
        path: 'tools',
        pathMatch: 'prefix',
        loadChildren: () => import('../app/tools/tools.module').then(m => m.ToolsModule),
      },
      {
        path: 'settings',
        pathMatch: 'prefix',
        loadChildren: () => import('../app/settings/settings.module').then(m => m.SettingsModule),
      },
    ],
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload', useHash: true})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
