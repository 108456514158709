import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgxsModule} from '@ngxs/store';
import {GlobalState} from '../state';
import {AuthInterceptorService} from './auth-interceptor.service';
import {ImporterService} from './importer.service';
import {ScanService} from './scan.service';
import {SettingsService} from './settings.service';
import {ToolService} from './tool.service';
import {UserService} from './user.service';

@NgModule({
  declarations: [],
  providers: [
    AuthInterceptorService,
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: AuthInterceptorService,
    },
    UserService,
    SettingsService,
    ImporterService,
    ToolService,
    ScanService,
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    NgxsModule.forFeature([GlobalState])
  ],
  exports: [
    HttpClientModule,
  ],
})
export class ApiModule {
}
