import {HttpParams} from '@angular/common/http';
import {ISearchCtx} from './interfaces';

export class API {
  public static  makeParams(ctx: ISearchCtx): HttpParams {
    let params = new HttpParams();

    for (const key in ctx) {
      if (Array.isArray((ctx as any)[key])) {
        for (const value of (ctx as any)[key]) {
          if (!value) {
            continue;
          }
          params = params.append(key, value);
        }
      } else {
        if (undefined === (ctx as any)[key] || null === (ctx as any)[key]) {
          continue;
        }
        params = params.append(key, (ctx as any)[key].toString());
      }
    }

    return params;
  }
}
